import { useCallback, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { filter, identity, pluck } from "ramda"

import tokens from "@ninjaone/tokens"
import {
  AlertMessage,
  Checkbox,
  FileAttachment,
  FileAttachmentUpload,
  Input,
  PhoneInput,
  Select,
  Text,
  TextArea,
} from "@ninjaone/components"
import { ExternalLinkIcon, MemoIconLight } from "@ninjaone/icons"
import { getTextSize } from "@ninjaone/utils"

import { toMB } from "js/includes/common/_conversions"
import {
  createZendeskTicket,
  uploadAttachmentToZendesk,
  deleteUploadedAttachment,
} from "js/includes/common/client/zendeskTicketRequest"
import { useForm, useMountedState } from "js/includes/common/hooks"
import {
  ZENDESK_DOJO_URL,
  debounce,
  getDojoSearchUrl,
  isEnterKey,
  localizationKey,
  localized,
  ninjaReportError,
  reportErrorAndShowMessage,
  showErrorMessage,
  showSuccessMessage,
  validations,
} from "js/includes/common/utils"
import { fetchHelpArticles, getHelpLinkAction } from "js/includes/components/HelpDropdown/helpDropdownData"
import { Box, Flex, StyledAnchor } from "js/includes/components/Styled"

import { categoryMapper, priorityMap } from "./zendeskMapper"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import Link from "js/includes/components/Link"
import Loading from "js/includes/components/Loading"
import { AppUsersAndContactsDropdown } from "js/includes/ticketing/shared/components"

const MAX_FILES = 5

const MAX_FILE_SIZE_ALLOWED_IN_MB = 50

const StyledTextArea = styled(TextArea)`
  min-height: 95px;
`

const StyledSelectWrapper = styled.div`
  [data-ninja-select-trigger] {
    border: 1px solid ${({ theme, hasError }) => (hasError ? theme.colorBorderError : theme.colorBorderWeak)};
    color: ${({ theme }) => theme.colorTextStrong};

    &:focus-visible {
      outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
    }
  }
`

const StyledDropdownWrapper = styled.div`
  [data-ninja-searchable-dropdown] {
    border: 1px solid ${({ theme, hasError }) => (hasError ? theme.colorBorderError : theme.colorBorderWeak)};
    color: ${({ theme }) => theme.colorTextStrong};
    box-shadow: none;

    &[data-active="true"] {
      outline: 2px solid ${({ theme }) => theme.colorForegroundFocus} !important; // must override default style
      box-shadow: none;
    }

    [role="search"] {
      min-height: 36px;
    }
  }
`

const StyledLabel = styled.label`
  all: unset; // reset bootstrap.css styles
  font-size: ${tokens.typography.fontSize.body};
  font-weight: ${tokens.typography.fontWeight.regular};
  line-height: ${tokens.typography.lineHeight};
  color: ${({ theme }) => theme.colorTextStrong};
`

const FormLabel = ({ label, htmlFor, id, isRequired = true }) => {
  return (
    <Box marginBottom={tokens.spacing[1]}>
      <StyledLabel id={id} htmlFor={htmlFor}>
        {label} {isRequired && <sup aria-hidden="true">*</sup>}
      </StyledLabel>
    </Box>
  )
}

const ZendeskTicketWidget = ({ handleComplete, locale, isBranded, setAlertProps }) => {
  const [isSubmitting, setIsSubmitting] = useMountedState(false)
  const [showError, setShowError] = useMountedState(false)
  const [files, setFiles] = useMountedState([])
  const [loading, setLoading] = useMountedState(false)
  const [fileSizeExceeded, setFileSizeExceeded] = useMountedState(null)
  const [
    { ticketSubject: countRelatedArticlesWithSubject, ticketBody: countRelatedArticlesWithBody },
    setCountRelatedArticles,
  ] = useMountedState({ ticketSubject: 0, ticketBody: 0 })

  const {
    values: { ticketPriority, ticketPhoneNumber, ticketSubject, ticketBody, ticketCategory, ticketCallback, ticketCc },
    validateForm,
    validation,
    onChange,
  } = useForm({
    fields: {
      ticketPriority: "",
      ticketPhoneNumber: "",
      ticketSubject: "",
      ticketBody: "",
      ticketCategory: null,
      ticketCallback: false,
      ticketCc: [],
    },
    validate: {
      ticketPriority: validations.required,
      ticketPhoneNumber: validations.phone,
      ticketSubject: validations.required,
      ticketBody: validations.required,
      ticketCategory: validations.required,
    },
  })

  const categoryOptions = useMemo(
    () =>
      categoryMapper
        .sort((a, b) => localized(a.labelToken).localeCompare(localized(b.labelToken)))
        .map(category => ({
          ...category,
          label: localized(category.labelToken),
        })),
    [],
  )

  const searchCriteriaForArticles = countRelatedArticlesWithSubject ? ticketSubject : ticketBody

  const uploadError = useMemo(
    () =>
      fileSizeExceeded
        ? {
            variant: "danger",
            children: (
              <Flex flexDirection="column" gap={tokens.spacing[2]}>
                <Text
                  token={localizationKey("Please select a different file. The following file(s) are too large: ")}
                  size="sm"
                  textWrap
                />

                <Box>
                  {fileSizeExceeded.map(({ name, index }) => (
                    <Text size="sm" key={`${name}-${index}`} textWrap>
                      {name}
                    </Text>
                  ))}
                </Box>
              </Flex>
            ),
          }
        : null,
    [fileSizeExceeded],
  )

  useEffect(() => {
    setAlertProps(uploadError)
    return () => setAlertProps(null)
  }, [setAlertProps, uploadError])

  const handleSubmit = async e => {
    e?.preventDefault()
    setShowError(false)
    if (isSubmitting || !validateForm()) return
    setLoading(true)
    setIsSubmitting(true)

    let imageTokens = []
    try {
      if (files?.length > 0) {
        const responses = await Promise.all(
          files.map(async file => {
            const response = await uploadAttachmentToZendesk(file)
            return response
          }),
        )
        imageTokens = filter(identity, pluck("token", responses))
      }

      const response = await createZendeskTicket({
        ticketSubject,
        ticketPhoneNumber,
        ticketBody,
        ticketPriority,
        ticketCategory: ticketCategory.value,
        imageTokens,
        ticketCallback,
        ticketCc,
      })
      if (response.resultCode === "FAILURE") {
        throw new Error("NOT_AUTHENTICATED")
      }
      showSuccessMessage(localized("Ticket sent"))
      handleComplete?.()
    } catch (error) {
      if (error.message === "NOT_AUTHENTICATED") {
        setShowError(true)
      } else {
        await deleteUploadedAttachment(imageTokens)
        reportErrorAndShowMessage(error)
      }
    } finally {
      setIsSubmitting(false)
      setLoading(false)
      setFiles([])
    }
  }

  const isFileSizeExceeded = size => parseFloat(toMB(size)) > MAX_FILE_SIZE_ALLOWED_IN_MB

  const uploadImage = attachments => {
    setFileSizeExceeded(null)
    const newFiles = Object.values(attachments).filter(file => {
      const isDuplicate = files.some(existingFile => {
        return existingFile.name === file.name
      })
      if (isDuplicate) {
        showErrorMessage(localized("File {{fileName}} has already been uploaded.", { fileName: file.name }))
      }
      if (isFileSizeExceeded(file.size)) {
        setFileSizeExceeded(currentExceededFiles => [...(currentExceededFiles || []), file])
        return false
      }
      return !isDuplicate
    })
    setFiles(currentFiles => [...currentFiles, ...newFiles])
  }

  const handleDelete = fileToDelete => {
    const remainingFiles = files.filter(file => file.name !== fileToDelete.name)
    setFiles(remainingFiles)

    if (!remainingFiles.some(file => isFileSizeExceeded(file.size))) {
      setFileSizeExceeded(null)
    }
  }

  const fetchRelatedArticles = useMemo(
    () =>
      debounce(async function(key, query) {
        try {
          const { results } = await fetchHelpArticles({ query, locale })

          if (!Array.isArray(results)) {
            throw new Error("Invalid results response")
          }

          setCountRelatedArticles(prevState => ({ ...prevState, [key]: results?.length }))
        } catch (error) {
          ninjaReportError(error)
        }
      }, 2000),
    [locale, setCountRelatedArticles],
  )

  const onChangeWithArticleSuggestion = useCallback(
    (key, value) => {
      onChange(key, value)
      if (
        isBranded ||
        (key === "ticketBody" && countRelatedArticlesWithSubject) ||
        value.replace(/\s/g, "").length < 3
      ) {
        return setCountRelatedArticles(prevState => ({ ...prevState, [key]: 0 }))
      }
      fetchRelatedArticles(key, value || searchCriteriaForArticles)
    },
    [
      countRelatedArticlesWithSubject,
      fetchRelatedArticles,
      isBranded,
      onChange,
      searchCriteriaForArticles,
      setCountRelatedArticles,
    ],
  )

  return (
    <form onSubmit={handleSubmit} id="zendesk-widget" name="zendesk-widget">
      <Box marginBottom={tokens.spacing[3]}>
        <FormLabel htmlFor="zendesk-widget-subject" label={localized("Subject")} />
        <Input
          id="zendesk-widget-subject"
          value={ticketSubject}
          errorMessage={validation.message.ticketSubject}
          onChange={e => onChangeWithArticleSuggestion("ticketSubject", e.target.value)}
          inputHeight="38px"
        />
      </Box>
      <Box validationState={validation.success.ticketBody === false ? "error" : null} marginBottom={tokens.spacing[3]}>
        <FormLabel htmlFor="zendesk-widget-message" label={localized("How can we help you?")} />
        <StyledTextArea
          id="zendesk-widget-message"
          errorMessage={validation.message.ticketBody}
          onChange={e => onChangeWithArticleSuggestion("ticketBody", e.target.value)}
          value={ticketBody}
          maxLength={500}
          minWidth="358px"
        />
        {!!(countRelatedArticlesWithSubject || countRelatedArticlesWithBody) && (
          <StyledAnchor
            fontSize={getTextSize("sm")}
            fontWeight={500}
            tabIndex={0}
            onClick={e => {
              e.preventDefault()
              getHelpLinkAction(getDojoSearchUrl(searchCriteriaForArticles))
            }}
            onKeyUp={e => {
              e.preventDefault()
              if (isEnterKey(e)) {
                getHelpLinkAction(getDojoSearchUrl(searchCriteriaForArticles))
              }
            }}
          >
            <Flex alignItems="center" gap={tokens.spacing[1]}>
              <MemoIconLight size="sm" />
              <Text size="sm" bold>
                {localized("{{count}} related articles found", {
                  count: countRelatedArticlesWithSubject || countRelatedArticlesWithBody,
                })}
              </Text>
              <ExternalLinkIcon size="xs" />
            </Flex>
          </StyledAnchor>
        )}
        {showError && (
          <Box marginTop={tokens.spacing[2]}>
            <AlertMessage
              variant="warning"
              labelToken={localizationKey(
                "You haven't registered in our Ninja Dojo. In order to submit a ticket, please click the link and return to this form. Thank you!",
              )}
              linkRenderer={() => <Link href={ZENDESK_DOJO_URL}>{localized("Dojo / Community")}</Link>}
            />
          </Box>
        )}
      </Box>
      <Box marginBottom={tokens.spacing[3]}>
        <FormLabel label={localized("Priority")} />
        <StyledSelectWrapper hasError={validation.success.ticketPriority === false}>
          <Select
            triggerAriaLabel={localized("Priority")}
            placeholderLabel={localized("Select priority")}
            popoverProps={{ portal: false }}
            value={ticketPriority || ""}
            onChange={tPriority => onChange("ticketPriority", tPriority)}
            options={priorityMap}
            errorMessage={validation.message.ticketPriority}
          />
        </StyledSelectWrapper>
      </Box>
      <Box marginBottom={tokens.spacing[2]}>
        <FormLabel id="zendesk-widget-category" label={localized("Category")} />
        <StyledDropdownWrapper hasError={validation.success.ticketCategory === false}>
          <SearchableDropDown
            width="100%"
            value={ticketCategory}
            onSelect={selection => onChange("ticketCategory", selection)}
            options={categoryOptions}
            searchPlaceholderToken={localizationKey("Search category")}
            noRowsRendererToken={localizationKey("No option available")}
            validationState={validation.success.ticketCategory === false ? "error" : null}
            errorMessage={validation.message.ticketCategory}
            ariaAttributes={{ "aria-labelledby": "zendesk-widget-category" }}
            useSelectStyling
          />
        </StyledDropdownWrapper>
      </Box>
      <Box marginBottom={tokens.spacing[2]}>
        <PhoneInput
          labelText={localized("Phone Number")}
          value={ticketPhoneNumber}
          onChange={value => onChange("ticketPhoneNumber", value)}
          {...{ ...(validation.message.ticketPhoneNumber && { errorMessage: localized("Invalid phone number") }) }}
        />
      </Box>
      <Box marginBottom={tokens.spacing[3]}>
        <FormLabel htmlFor="zendesk-widget-cc" label={localized("CC")} isRequired={false} />
        <AppUsersAndContactsDropdown
          userType="TECHNICIAN"
          onSelect={values => {
            const ticketCc = values.map(userEmail => ({ user_email: userEmail.email }))
            onChange("ticketCc", ticketCc)
          }}
          isMulti
        />
      </Box>
      <Box marginBottom={tokens.spacing[6]}>
        <Checkbox
          label={localized("Request a call from Support Team")}
          onChange={({ isChecked }) => onChange("ticketCallback", isChecked)}
        />
      </Box>
      <Flex marginBottom={tokens.spacing[6]}>
        <FileAttachmentUpload
          titleToken={localizationKey("File attachments ({{currentFileCount}})")}
          currentFileCount={files.length}
          buttonLabelToken={localizationKey("Add attachments")}
          labelTokenDescription={localizationKey(
            `Maximum of ${MAX_FILES} files, up to ${MAX_FILE_SIZE_ALLOWED_IN_MB} MB each (JPEG, JPG, PNG, MOV or TXT file only)`,
          )}
          onSelect={uploadImage}
          accept=".jpg, .jpeg, .png, .txt, .mov"
          multiple
          maxFiles={MAX_FILES}
          errorMessageToken={localizationKey("Only {{maxFiles}} attachments are allowed.")}
        />
      </Flex>
      <Box marginBottom={tokens.spacing[3]}>
        {files?.map(file => (
          <Box paddingBottom={tokens.spacing[2]}>
            <FileAttachment
              {...{
                key: `${file.name}-${file.index}`,
                file,
                actions: [{ labelToken: localizationKey("Delete"), action: () => handleDelete(file), isRed: true }],
              }}
            />
          </Box>
        ))}
      </Box>
      {loading && (
        <Flex justifyContent="center" marginTop={tokens.spacing[5]} marginBottom={tokens.spacing[3]}>
          <Loading
            loadingText={localized("Submitting help ticket")}
            css={theme => css`
              color: ${theme.colorTextWeakest};
            `}
          />
        </Flex>
      )}
    </form>
  )
}

export default connect(({ application: { locale, isBranded } }) => ({
  locale,
  isBranded,
}))(ZendeskTicketWidget)
