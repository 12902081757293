import { memo, useCallback, useEffect } from "react"
import Loading from "js/includes/components/Loading"
import {
  localized,
  localizationKey,
  debugLog,
  fetchJson,
  ninjaReportError,
  showSuccessMessage,
  showErrorMessage,
  user,
  noop,
  reportErrorAndShowMessage,
} from "js/includes/common/utils"
import { Modal } from "react-bootstrap"
import RestoreJobStatus from "./RestoreJobStatus"
import { canCancelLockhartJob, cancelLockhartJobs, isLockhartEnabled } from "js/includes/common/backup"
import { useMountedState } from "js/includes/common/hooks"
import { getBackupJob, submitJob } from "js/includes/common/backup"
import { clearBackupJob } from "js/includes/common/_jobs"
import { Box } from "../Styled"
import { colors } from "js/includes/common/theme"
import { useWampProcedures } from "./useWampProcedures"

const getLatestJobDataFromServer = async jobUID => {
  const response = await fetchJson(`/webapp/job/${jobUID}`)

  if (response.resultCode === "SUCCESS") {
    return response.job
  }

  return {}
}

const showRetryOption = async ({ nodeId, isUp }) => {
  return isUp && (await isLockhartEnabled({ nodeId }))
}

const runBackupPlan = async ({ nodeId, nodeName, planId, planName, refreshCallback = noop }) => {
  try {
    const backupJob = getBackupJob({ nodeId, nodeName, planId, planName })
    await submitJob([backupJob])
    showSuccessMessage(localized("Backup job created successfully"))
    refreshCallback()
  } catch (error) {
    showErrorMessage(localized("Error running backup job"))
    ninjaReportError(error)
  }
}

const clearFailedBackupJob = async ({ nodeId, planId, jobId, unmount, refreshCallback = noop }) => {
  try {
    await clearBackupJob(nodeId, planId, jobId)
    refreshCallback()
    unmount()
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error clearing backup job"))
  }
}

export default memo(function LockhartJobDetailsModal({ node, job: _job, unmount, refreshCallback, loadingNodeData }) {
  const [job, setJob] = useMountedState(_job)
  const [completed, setCompleted] = useMountedState(false)
  const [error, setError] = useMountedState("")
  const [updatingJob, setUpdatingJob] = useMountedState(true)
  const { actionType } = job?.content ?? {}
  const jobUID = _job.jobUid || _job.uid
  useWampProcedures({ node, jobUID, setError })

  const loading = updatingJob || loadingNodeData

  const updateJob = useCallback(
    async showLoading => {
      try {
        showLoading && setUpdatingJob(true)
        const updatedJob = await getLatestJobDataFromServer(jobUID)

        debugLog("Job from server ", updatedJob)
        setJob(updatedJob)

        if (updatedJob.jobStatus === "COMPLETED") {
          setCompleted(true)
        }
      } catch (error) {
        ninjaReportError(error)
      } finally {
        showLoading && setUpdatingJob(false)
      }
    },
    [jobUID, setCompleted, setJob, setUpdatingJob],
  )

  useEffect(() => {
    updateJob(true)
  }, [updateJob])

  const shouldShowRetryOption = showRetryOption({ nodeId: node.id, isUp: node.isUp })

  return (
    <Modal.Dialog bsClass="inmodal modal" dialogClassName={"restore-manager-download-modal"}>
      <Modal.Header>
        <Modal.Title>{localized("Restore")}</Modal.Title>
      </Modal.Header>

      <Modal.Body bsClass={"modal-body overflow-visible-important display-flex flex-direction-column"}>
        {loading ? (
          <Loading />
        ) : (
          <>
            {actionType === "LOCKHART_RESTORE" && (
              <RestoreJobStatus {...{ job, node, error, setCompleted, setError }} />
            )}
            {!actionType && <Box color={colors.ninjaRed}>{localized("Error getting job details")}</Box>}
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <div className="display-flex justify-content-between">
          <div className="display-flex flex-full justify-content-end">
            {completed || error || !actionType ? (
              <>
                {error && (
                  <button type="button" className="btn btn-white" onClick={() => window.location.reload()}>
                    {localized("Reload")}
                  </button>
                )}
                {shouldShowRetryOption && user("canUpdateDevices", node) && user("canViewAndManageBackupData") && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() =>
                      runBackupPlan({
                        nodeId: job.content.nodeId,
                        nodeName: job.content.nodeName,
                        planId: job.content.planId,
                        planName: job.content.planName,
                        refreshCallback,
                      })
                    }
                  >
                    {localized("Retry")}
                  </button>
                )}
                {user("canUpdateDevices", node) && user("canViewAndManageBackupData") && (
                  <button
                    type="button"
                    className="btn btn-white"
                    onClick={() =>
                      clearFailedBackupJob({
                        nodeId: job.content.nodeId,
                        planId: job.content.planId,
                        jobId: job.jobUid || job.uid,
                        unmount,
                        refreshCallback,
                      })
                    }
                  >
                    {localized("Clear")}
                  </button>
                )}
                <button type="button" className="btn btn-white" onClick={unmount}>
                  {localized("Close")}
                </button>
              </>
            ) : (
              <>
                <button type="button" className="btn btn-primary" onClick={unmount}>
                  {localized("Close And Continue")}
                </button>

                {canCancelLockhartJob(actionType) && (
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={async () => {
                      const proceed = await cancelLockhartJobs([{ ...job, nodeId: node.id }])
                      proceed && unmount()
                    }}
                  >
                    {localized("Cancel Job")}
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </Modal.Footer>
    </Modal.Dialog>
  )
})
