import React, { memo } from "react"
import PropTypes from "prop-types"
import { colors } from "js/includes/common/theme"

const transparent = "rgba(0,0,0,0)"

const ProgressBar = ({ height, progress, borderColor, backgroundColor, progressBarFillColor, borderRadius }) => {
  return (
    <div
      style={{
        height: `${height}px`,
        textAlign: "left",
        width: "100%",
        position: "relative",
        overflow: "hidden",
        ...(borderRadius ? { borderRadius } : {}),
      }}
    >
      <svg
        width="100%"
        height={height}
        style={{
          position: "absolute",
          left: 0,
        }}
      >
        <rect
          width="100%"
          height={height}
          style={{
            fill: backgroundColor,
            strokeWidth: 1,
            stroke: borderColor,
          }}
        />
      </svg>

      <svg
        width="100%"
        height={height}
        style={{
          position: "absolute",
          left: 0,
        }}
      >
        <rect
          width={`${progress}%`}
          height={height}
          style={{
            fill: progressBarFillColor,
            zIndex: 10,
          }}
        />
      </svg>
    </div>
  )
}

ProgressBar.propTypes = {
  height: PropTypes.number,
  progress: PropTypes.number.isRequired,
  borderColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  progressBarFillColor: PropTypes.string,
  borderRadius: PropTypes.string,
}

ProgressBar.defaultProps = {
  height: 40,
  progress: 0,
  borderColor: transparent,
  backgroundColor: transparent,
  progressBarFillColor: colors.ninjaBlueSaturated,
}

export default memo(ProgressBar)
